import React, { useEffect } from "react";
import Header from "./sections/Header";
import Hero from "./sections/Hero";
import Footer from "./sections/Footer";
import Services from "./sections/Services";
import LogoCloud from "./sections/LogoCloud";
import Testimonials from "./sections/Testimonials";
import Cta from "./sections/Cta";
import Syarat from "./sections/Syarat";
import Timeline from "./sections/Timeline";
import Blog from "./sections/Blog";
import Faq from "./sections/Faq";

import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import Contact from "./sections/Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import { ApiProvider } from "../../context/ApiProvider";

function HomePage() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <ApiProvider>
      
      <Header />
      <div className="relative body-font font-poppins px-4">
      <Hero />
      <LogoCloud />
      <Cta />
      <Syarat />
      <Timeline />
      <Services />
      <Blog />
      <Testimonials />
      <Faq />
      <Contact />
      <Footer />
      <WhatsAppWidget
        phoneNumber={localStorage.getItem('whatsapp') && localStorage.getItem('whatsapp')}
        companyName="Transpublika"
        message="Hallo! 👋🏼 Apa yang bisa saya bantu?"
        sendButtonText="Kirim"
        inputPlaceHolder="Tulis pesan"
        replyTimeText="Akan dibalas secepatnya"
      />
    </div>
    </ApiProvider>
  );
}

export default HomePage;
