import React from "react";
import { graduatiion_img } from "../../../assets/img";

function Hero() {
  return (
    <section id="home" data-aos="fade-up" class="mx-auto mb-20 relative max-w-screen-xl flex flex-col justify-between gap-6 sm:gap-10 md:gap-16 lg:flex-row">
      <div class="flex flex-col items-center justify-center sm:text-center lg:items-start lg:py-12 lg:text-left xl:w-5/12 xl:py-24">
        <h1 style={{lineHeight:1.2}} class="mb-8 text-4xl font-extrabold text-black sm:text-5xl md:mb-12 md:text-5xl">
        {localStorage.getItem('web-title')}
        </h1>

        <p class="mb-8 leading-relaxed text-gray-500 md:mb-12 lg:w-4/5 xl:text-lg">
        {localStorage.getItem('tag-line')}
        </p>

        <a href={localStorage.getItem('link-form')} class="inline-block rounded bg-blue-800 px-8 py-2 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">
            Hubungi Sekarang
          </a>
      </div>

      <div class="h-48 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:h-auto xl:w-5/12">
        <img
          src={graduatiion_img}
          loading="lazy"
          alt="Photo by Fakurian Design"
          class="h-full w-full object-cover object-center"
        />
      </div>
    </section>
  );
}

export default Hero;
