import React from "react";
import Header from "../home/sections/Header";
import Footer from "../home/sections/Footer";
import { mockup1, mockup2 } from "../../assets/img/mockups";

function JurnalKami() {
  return (
    <>
      <Header />
      <div className="flex gap-6 justify-around max-w-screen-lg mx-auto py-20 flex-col md:flex-row">
        <a href="https://ojs.transpublika.com" target="_blank">
          <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[16px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px]">
            <div class="rounded-xl overflow-hidden h-[140px] md:h-[262px]">
              <img
                src={mockup1}
                class="dark:hidden h-[140px] md:h-[262px] w-full rounded-xl"
                alt=""
              />
              <img
                src={mockup1}
                class="hidden dark:block h-[140px] md:h-[262px] w-full rounded-xl"
                alt=""
              />
            </div>
          </div>
          <div class="relative mx-auto bg-gray-900 dark:bg-gray-700 rounded-b-xl h-[24px] max-w-[301px] md:h-[42px] md:max-w-[512px]"></div>
          <div class="relative mx-auto bg-gray-800 rounded-b-xl h-[55px] max-w-[83px] md:h-[95px] md:max-w-[142px]"></div>
        </a>
        <a href="https://transpublika.co.id/ojs/" target="_blank">
          <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[16px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px]">
            <div class="rounded-xl overflow-hidden h-[140px] md:h-[262px]">
              <img
                src={mockup2}
                class="dark:hidden h-[140px] md:h-[262px] w-full rounded-xl"
                alt=""
              />
              <img
                src={mockup2}
                class="hidden dark:block h-[140px] md:h-[262px] w-full rounded-xl"
                alt=""
              />
            </div>
          </div>
          <div class="relative mx-auto bg-gray-900 dark:bg-gray-700 rounded-b-xl h-[24px] max-w-[301px] md:h-[42px] md:max-w-[512px]"></div>
          <div class="relative mx-auto bg-gray-800 rounded-b-xl h-[55px] max-w-[83px] md:h-[95px] md:max-w-[142px]"></div>
        </a>
      </div>
      <Footer />
    </>
  );
}

export default JurnalKami;
