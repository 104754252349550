import React from "react";
import { PhoneIcon } from "../../../components/svgs";
import {
  FaEnvelope,
  FaFacebook,
  FaFacebookF,
  FaInstagram,
  FaMapMarker,
  FaPhoneAlt,
  FaTwitch,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { transpublika_logo_img } from "../../../assets/img/logo";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header style={{ display: "unset" }} class="z-50 relative">
      <nav class=" bg-blue-800 dark:bg-gray-900 text-white sticky top-0 hidden md:block">
        <div class="flex items-end justify-between mx-auto max-w-screen-2xl px-4 py-2">
          <div class="flex gap-4 items-center">
            <span class="flex gap-2 justify-center items-center text-sm">
              <FaMapMarker />
              <p>
                {localStorage.getItem('alamat')}
              </p>
            </span>
            <a href="mailto:admin@publikasijurnal.com">
              <span class="flex gap-2 justify-center items-center text-sm">
                <FaEnvelope />
                <p>{localStorage.getItem('email')}</p>
              </span>
            </a>
          </div>
          <div class="flex gap-3 justify-center items-end">
            <FaInstagram />
            <FaWhatsapp />
            <FaFacebookF />
            <FaTwitter />
          </div>
        </div>
      </nav>
      <nav class="bg-white border-gray-200 dark:bg-gray-900 hidden md:block">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
          <a href="https://flowbite.com" class="flex items-center">
            <img
              src={localStorage.getItem('web-logo')}
              class="h-10 mr-3"
              alt="Flowbite Logo"
            />
          </a>
          <div class="flex flex-col justify-end items-end">
            <span class="flex gap-2 justify-center items-center">
              <FaPhoneAlt className="text-blue-800" />
              <h3 class="text-lg font-semibold text-blue-800">
                Ingin konsultasi gratis ?
              </h3>
            </span>
            <a href="https://api.whatsapp.com/send/?phone=6281234566573&text&type=phone_number&app_absent=0">
              <h1 className="text-2xl font-extrabold text-black">
                {localStorage.getItem('whatsapp')}
              </h1>
            </a>
          </div>
        </div>
      </nav>
      <nav
        class="bg-white border-gray-200 dark:bg-gray-900 sticky top-0 md:top-9 self-stretch"
        style={{ position: "-webkit-sticky" }}
      >
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="https://flowbite.com/" class="flex items-center  md:hidden ">
            <img
              src={localStorage.getItem('web-logo')}
              class="h-8 mr-3"
              alt="Flowbite Logo"
            />
          </a>
          <button
            onClick={() => {
              document
                .getElementById("navbar-default")
                .classList.toggle("hidden");
            }}
            data-collapse-toggle="navbar-default"
            type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div class="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href="/#home"
                  class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/#about"
                  class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Tentang
                </a>
              </li>
              <li>
                <a
                  href="/#services"
                  class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Layanan
                </a>
              </li>
              <li>
                <Link
                  to="/blog"
                  class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/jurnal_kami"
                  class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Jurnal Kami
                </Link>
              </li>
              <li>
                <a
                  href="/#kontak"
                  class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Kontak
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
