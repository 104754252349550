import React from "react";
import { girl_wisuda_imgs } from "../../../assets/img";

function Cta() {
  return (
    <section id="about" data-aos="fade-up" class="bg-white dark:bg-gray-900 md:mb-20">
      <div class="gap-8 items-center mx-auto max-w-screen-xl xl:gap-16 flex-col md:flex-row-reverse md:flex md:justify-between ">
        <img
          class="w-full md:w-1/3 dark:hidden"
          src={girl_wisuda_imgs}
          alt="dashboard image"
        />
        <div class="mt-4 md:mt-0 w-full md:w-1/2">
          <h2
            class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"
            style={{ lineHeight: 1.4 }}
          >
            Kenapa harus publish jurnal di{" "}
            <span class="text-blue-800">publikasijurnal.com ?</span>
          </h2>
          <p class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
            Layanan Jasa Publikasi Artikel yang kami tangani diperuntukkan bagi
            Semua Mahasiswa, Akademisi atau Dosen Sebagai syarat kelulusan (S1 /
            S2 / S3 / UMUM) atau Angka Kredit Pengajuan Jabatan Fungsional
            (Jafung) (UMUM)
          </p>
          <a
            href="#"
            class="inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900"
          >
            Get started
            <svg
              class="ml-2 -mr-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Cta;
