import React, { useEffect, useState } from "react";
import api from "../../api/api";
import Swal from "sweetalert2";
import ArticleSummary from "../../components/blocks/article";

function BlogLists() {
  const [article, setArticle] = useState([]);
  useEffect(() => {
    api
      .getData("/web-content/article/*")
      .then((res) => {
        setArticle(res.data.data.article);
      })
      .catch((err) => {
        Swal.fire(
          "Terjadi kesalahan!",
          "Silahkan refresh halaman ini!",
          "error"
        );
      });
  }, []);
  return (
    <section class="bg-white dark:bg-gray-900 py-20">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="grid gap-8 lg:grid-cols-2">
          {Object.keys(article).map((el) => {
            return <ArticleSummary key={article[el].id} data={article[el]} />;
          })}
        </div>
      </div>
    </section>
  );
}

export default BlogLists;
