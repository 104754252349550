import React from "react";

function FaqWrapper({ children }) {
  return (
    <div class="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
      {children}
    </div>
  );
}

export default FaqWrapper;
