import React from "react";
import { copernicus_index_img, crossreff_index_img, thompson_index_img, wof_index_img } from "../../../assets/img/indexing";

function LogoCloud() {
  return (
    <div data-aos="fade-up" class="bg-white py-6 sm:py-8 lg:py-12 mb-20">
      <div class="mx-auto max-w-screen-xl ">
        <div class="mb-4 flex flex-col items-center md:mb-8 lg:flex-row lg:justify-between">
          <h2 class="mb-2 text-center font-extrabold tracking-tight text-gray-900 lg:mb-0 text-4xl">
            Indeksasi Jurnal
          </h2>

          <p class="max-w-md text-center text-gray-400 lg:text-right">
            Jurnal anda akan terindeks di berbagai database.
          </p>
        </div>

        <div class="grid grid-cols-1 gap-4 rounded-lg md:grid-cols-4 lg:gap-6">
          <div class="flex items-center justify-center rounded-lg bg-gray-100 p-2 text-gray-400 sm:h-32">
            <img src={copernicus_index_img} alt="copernicus logo" />
          </div>
          <div class="flex items-center justify-center rounded-lg bg-gray-100 p-2 text-gray-400 sm:h-32">
            <img src={wof_index_img} alt="WOF logo" />
          </div>
          <div class="flex items-center justify-center rounded-lg bg-gray-100 p-2 text-gray-400 sm:h-32">
          <img src={crossreff_index_img} alt="WOF logo" />
          </div>
          <div class="flex items-center justify-center rounded-lg bg-gray-100 p-2 text-gray-400 sm:h-32">
          <img src={thompson_index_img} alt="Thompson logo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoCloud;
