import React from "react";

function RadioForm({ children, label }) {
  return (
    <div>
      <label
        for="countries"
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {label}
      </label>
      <fieldset>{children}</fieldset>
    </div>
  );
}

function RadioItem({ children, name, onChange }) {
  return (
    <div class="flex items-center mb-4">
      <input
        id={name}
        type="radio"
        name={name}
        value={children}
        onChange={onChange}
        class="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
        
      />
      <label
        for={name}
        class="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {children}
      </label>
    </div>
  );
}

export default RadioForm;
export { RadioItem };
