import React from "react";
import { FaUser } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Testimonials() {
  return (
    <div data-aos="fade-up"  class="bg-white py-16 sm:py-8 lg:py-20">
      <div class="mx-auto max-w-screen-xl px-4 md:px-8">
        <h2 class="mb-8 text-center font-extrabold text-gray-800 md:mb-12 text-4xl">
          Survey Kepuasan Pelanggan
        </h2>

        <Carousel
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          showArrows={false}
          autoPlay={true}
        >
          <div class="flex flex-col items-center gap-4 rounded-lg bg-blue-800 px-8 py-6 md:gap-6 m-4">
            <div class="max-w-md text-center text-white lg:text-lg">
              Terimakasih banyak Mas. Alhamdulillah cepet banget prosesnya
              padahal baru minta tolong terbit akhir Desember, pertengahan
              Januari sudah terbit.
            </div>

            <div class="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
              <FaUser size={30} color="white" />

              <div>
                <div class="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base">
                  ES
                </div>
                <p class="text-center text-sm text-indigo-200 sm:text-left md:text-sm">
                  Master of Communication and Business Institute, London School
                  of Public Relation, Indonesia
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-center gap-4 rounded-lg bg-blue-800 px-8 py-6 md:gap-6 m-4">
            <div class="max-w-md text-center text-white lg:text-lg">
              Terimakasih ya Pak. Sukses dan tetap sehat selalu. Kalau ada
              jurnal kami lagi. Mohon bantuannya ya Pak
            </div>

            <div class="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
              <FaUser size={30} color="white" />

              <div>
                <div class="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base">
                  YF
                </div>
                <p class="text-center text-sm text-indigo-200 sm:text-left md:text-sm">
                  Dosen Akuntansi, Universitas Batam
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-center gap-4 rounded-lg bg-blue-800 px-8 py-6 md:gap-6 m-4">
            <div class="max-w-md text-center text-white lg:text-lg">
            Alhamdulillah sedang proses: Publikasi saya sudah ACC, menjadi syarat wisuda dan ijazah.
            </div>

            <div class="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
              <FaUser size={30} color="white" />

              <div>
                <div class="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base">
                NN
                </div>
                <p class="text-center text-sm text-indigo-200 sm:text-left md:text-sm">
                Dosen Universitas Respati indonesia & Program Doktor Ilmu Administrasi (FIA) Universitas Brawijaya, Malang.
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Testimonials;
