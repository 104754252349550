import React from "react";
import { call5, call2, call3, call4 } from "../../../assets/img/callforpaper";
import FeaturesWrapper from "../../../components/blocks/feature";
import FeaturesBody, {
  FeaturesDesc,
  FeaturesTitle,
} from "../../../components/blocks/feature/content";
import { AiFillPrinter, AiOutlineTranslation } from "react-icons/ai";
import {
  FaAssistiveListeningSystems,
  FaJournalWhills,
  FaSearch,
} from "react-icons/fa";
import { BiText } from "react-icons/bi";

function Services() {
  return (
    <div id="services" data-aos="fade-up" class="bg-white py-8 sm:py-8 lg:py-12 min-h-screen">
      <div class="mx-auto max-w-screen-xl px-4 md:px-8 mb-6">
        <div class="mb-10 md:mb-16">
          <h2 class="mb-4 text-center font-extrabold text-gray-800 md:mb-6 text-4xl">
            Layanan Kami
          </h2>

          <p class="mx-auto max-w-screen-lg text-center text-gray-500 md:text-lg">
            Selain menerbitkan artikel di jurnal terakreditasi nasional dan
            bereputasi internasional, Transpublika juga berkomitmen memberikan
            dukungan kepada para akademisi, dosen dan peneliti dalam menyebarkan
            hasil karya ilmiah dan publikasi di jurnal internasional, yang
            diwujudkan ke dalam layanan kepenulisan, Penerjemahan dan Publikasi.
          </p>
        </div>
        <div class="grid gap-8 sm:grid-cols-2 md:gap-12 xl:grid-cols-3 xl:gap-16">
          <div class="flex gap-4 md:gap-6">
            <div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-blue-800 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
            <AiOutlineTranslation size={30} />
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold md:text-xl">
                Translate / Penerjemahan
              </h3>
              <p class="mb-2 text-gray-500">
                Kami menangani semua jenis proyek terjemahan setiap hari. Tim
                penerjemah kami yang berpengalaman dan berkualifikasi akan
                melakukan yang terbaik untuk menangani semua kebutuhan
                terjemahan Anda. Kami Sangat Mengedepankan Kualitas!
              </p>
            </div>
          </div>

          <div class="flex gap-4 md:gap-6">
            <div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-blue-800 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
            <FaSearch size={30} />
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold md:text-xl">Proofread</h3>
              <p class="mb-2 text-gray-500">
                Merupakan layanan penyuntingan bahasa untuk naskah artikel
                ilmiah yang disiapkan untuk publikasi ke jurnal nasional dan
                internasional (semua pengindeks). Layanan disupervisi oleh
                editor bahasa native speaker (penutur asli Bahasa Inggris)
                bergelar Ph.D / M.Ed.
              </p>
            </div>
          </div>

          <div class="flex gap-4 md:gap-6">
            <div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-blue-800 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
            <BiText size={30} />
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold md:text-xl">
                Paraphrase (untuk menurunkan similarity)
              </h3>
              <p class="mb-2 text-gray-500">
                Merupakan layanan penyuntingan bahasa untuk naskah artikel
                ilmiah yang disiapkan untuk publikasi ke jurnal nasional dan
                internasional (semua pengindeks). Layanan disupervisi oleh
                editor bahasa native speaker (penutur asli Bahasa Inggris)
                bergelar Ph.D / M.Ed.
              </p>
            </div>
          </div>

          <div class="flex gap-4 md:gap-6">
            <div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-blue-800 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
            <FaAssistiveListeningSystems size={30} />
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold md:text-xl">
                Layanan Asistensi Publikasi
              </h3>
              <p class="mb-2 text-gray-500">
                Layanan ini bertujuan membantu Anda mengubah bahan tulisan (bisa
                berupa gagasan, skripsi, tesis, disertasi, artikel, maupun modul
                ajar) menjadi artikel yang layak dipublikasikan.
              </p>
            </div>
          </div>

          <div class="flex gap-4 md:gap-6">
            <div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-blue-800 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
            <FaJournalWhills size={30} />
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold md:text-xl">
                Asistensi Pengelolaan Jurnal
              </h3>
              <p class="mb-2 text-gray-500">
                Layanan Asistensi Pengelolaan Jurnal ( Pembuatan Jurnal OJS,
                Indeksasi, Kustomisasi, Setting DOI dan Akreditasi Jurnal)
              </p>
            </div>
          </div>

          <div class="flex gap-4 md:gap-6">
            <div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-blue-800 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
            <FaJournalWhills size={30} />
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold md:text-xl">
                Pengelolaan Jurnal Institusi atau Lembaga
              </h3>
              <p class="mb-2 text-gray-500">
                Layanan Asistensi Pengelolaan Jurnal ( Pembuatan Jurnal OJS,
                Indeksasi, Kustomisasi, Setting DOI dan Akreditasi Jurnal )
                Institusi atau Lembaga
              </p>
            </div>
          </div>

          <div class="flex gap-4 md:gap-6">
            <div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-blue-800 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
            <AiFillPrinter size={30} />
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold md:text-xl">
                Percetakan Buku ISBN
              </h3>
              <p class="mb-2 text-gray-500">Layanan pencetakan Buku ISBN</p>
            </div>
          </div>
        </div>
      </div>

      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="max-w-screen-md mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Call For Paper
          </h2>
        </div>
        <div class="space-y-8 lg:grid lg:grid-cols-4 sm:gap-6 xl:gap-10 lg:space-y-0">
          <div class="flex flex-col mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
            <img src={call2} alt="" />
          </div>
          <div class="flex flex-col mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
            <img src={call3} alt="" />
          </div>
          <div class="flex flex-col mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
            <img src={call4} alt="" />
          </div>
          <div class="flex flex-col mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white">
            <img src={call5} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
