import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { WhatsAppWidget } from "react-whatsapp-widget";
import Header from "../home/sections/Header";
import Footer from "../home/sections/Footer";

function Blog() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <WhatsAppWidget
        phoneNumber={localStorage.getItem('whatsapp')}
        companyName="Transpublika"
        message="Hallo! 👋🏼 Apa yang bisa saya bantu?"
        sendButtonText="Kirim"
        inputPlaceHolder="Tulis pesan"
        replyTimeText="Akan dibalas secepatnya"
      />
    </>
  );
}

export default Blog;
