import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { NotFound } from "./pages/error";
import HomePage from "./pages/home";
import BlogList from "./pages/blog/blogLists";
import Blog from "./pages/blog/blog";
import BlogPost from "./pages/blog/blogPost";
import JurnalKami from "./pages/jurnal_kami";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <NotFound />,
  },
  {
    path: "/blog",
    element: <Blog />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <BlogList />,
      },
      {
        path: ":slug",
        element: <BlogPost/>,
      },
    ],
  },
  {
    path: "jurnal_kami",
    element: <JurnalKami/>,
    errorElement: <NotFound/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
