import React from "react";
import { FaUser } from "react-icons/fa";
import {
  TB,
  TbSquareRoundedNumber1,
  TbSquareRoundedNumber2,
  TbSquareRoundedNumber3,
  TbSquareRoundedNumber4,
  TbSquareRoundedNumber5,
} from "react-icons/tb";
import TimeLineItem from "../../../components/blocks/timeline";

function Timeline() {
  return (
    <div data-aos="fade-up" class="flex justify-center content-start max-w-screen-xl mx-auto min-h-screen py-8 px-4">
      <div>
        <div class="mb-10 md:mb-16">
          <h2 class="mb-4 text-left font-extrabold text-gray-800 md:mb-6 text-4xl">
            Alur Publikasi
          </h2>

          <p class="mx-auto max-w-screen-xl text-left text-gray-500 md:text-lg">
            Alur publikasi yang akan dilewati jika publish jurnal di{" "}
            <span className="text-blue-800">publikasijurnal.com</span>
          </p>
        </div>
        <ol class="relative border-l border-blue-800  dark:border-blue-700">
          <TimeLineItem
            icon={<TbSquareRoundedNumber1 size={25} />}
            title="Konsultasi"
            desc="Pengguna jasa berkonsultasi dengan kami untuk pengiriman Jurnal Nasional / International sesuai target Jurnal yang diinginkan."
          />
          <TimeLineItem
            icon={<TbSquareRoundedNumber2 size={25} />}
            title="Kirim Artikel"
            desc="Setelah ada kesepakatan, artikel silahkan dikirim via google form / email: hardinansyah88@gmail.com / WA kami 081234560500 / 081 123 1 666"
          />
          <TimeLineItem
            icon={<TbSquareRoundedNumber3 size={25} />}
            title="Informasi Biaya"
            desc="Setelah artikel diterima, admin akan memberikan balasan berupa informasi total biaya yang harus dibayarkan."
          />
          <TimeLineItem
            icon={<TbSquareRoundedNumber4 size={25} />}
            title="DP 50%"
            desc="Pengguna Jasa melakukan pembayaran DP sebesar 50% dari total biaya yang harus di bayarkan."
          />
          <TimeLineItem
            icon={<TbSquareRoundedNumber5 size={25} />}
            title="Proses Publikasi"
            desc="Setelah DP diterima, Kami akan melakukan proses kerja sesuai dengan kesepakatan hingga LOA rilis / Bukti Kerja kami selesai."
          />
          <TimeLineItem
            icon={<TbSquareRoundedNumber5 size={25} />}
            title=" Artikel Terpublikasikan"
            desc="Pengguna Jasa melakukan pelunasan Pembayaran. Setelah itu Admin akan mengirimkan bukti LOA asli, Akun OJS Jurnal, dan Artikel yang sudah di Edit template Final akan diserahkan."
          />
        </ol>
      </div>
    </div>
  );
}

export default Timeline;
