import React from "react";
import { TbSquareRoundedNumber1 } from "react-icons/tb";

function TimeLineItem({title,desc, icon}) {
  return (
    <li class="mb-10 ml-6">
      <span class="absolute flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full -left-5 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
        {icon}
      </span>
      <div class="p-4 bg-white border border-blue-800 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
        <div class="items-center justify-between mb-3 sm:flex">
          <div class="text-base font-bold text-black lex dark:text-gray-300">
            {title}
          </div>
        </div>
        <div class="p-3 text-base font-normal text-gray-900 border border-blue-800 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
          {desc}
        </div>
      </div>
    </li>
  );
}

export default TimeLineItem;
