import React from "react";
import { FaBook } from "react-icons/fa";
import { BsTranslate } from "react-icons/bs";

function Syarat() {
  return (
    <div data-aos="fade-up" class="bg-white py-6 sm:py-8 lg:py-12 mb-20">
      <div class="mx-auto max-w-screen-xl px-4 py-8 md:px-8">
        <div class="mb-10 md:mb-16">
          <h2 class="mb-4 text-center font-extrabold text-gray-800 md:mb-6 text-4xl">
            Syarat Publikasi
          </h2>

          <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
            Syarat yang harus dipenuhi jika publish di <span className="text-blue-800">publikasijurnal.com</span>
          </p>
        </div>

        <div class="grid gap-4 sm:grid-cols-2 md:gap-8 xl:grid-cols-2">
          <div class="flex divide-x rounded-lg border bg-gray-50">
            <div class="flex items-center p-2 text-blue-800 md:p-4">
              {<FaBook size={50} />}
            </div>

            <div class="p-4 md:p-6">
              <h3 class="mb-2 text-lg font-semibold md:text-xl">Artikel</h3>
              <p class="text-gray-500">
                Pengguna Jasa Sudah Memiliki Artikel sebagai bahan untuk
                dipublikasikan baik dalam Bahasa Indonesia / Bahasa Inggris.
              </p>
            </div>
          </div>
          <div class="flex divide-x rounded-lg border bg-gray-50">
            <div class="flex items-center p-2 text-blue-800 md:p-4">
              <BsTranslate size={50} />
            </div>

            <div class="p-4 md:p-6">
              <h3 class="mb-2 text-lg font-semibold md:text-xl">Alih Bahasa</h3>
              <p class="text-gray-500">
                Jika artikel Masih dalam Bahasa Indonesia, Kami akan Melakukan
                alih bahasa secara profesional dengan tarif tambahan.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Syarat;
