import React from "react";
import { transpublika_logo_img } from "../../../assets/img/logo";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer class="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800 mt-10">
      <div class="mx-auto max-w-screen-xl text-center">
        <a
          href="#"
          class="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className=" max-h-12" src={localStorage.getItem('web-logo')} alt="" />
        </a>
        <p class="my-6 text-gray-500 dark:text-gray-400">
       {localStorage.getItem('alamat')}
        </p>
        <ul class="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
          <li>
            <a href="/#home" class="mr-4 hover:underline md:mr-6 ">
              Home
            </a>
          </li>
          <li>
            <a href="/#about" class="mr-4 hover:underline md:mr-6">
              Tentang
            </a>
          </li>
          <li>
            <a href="/#services" class="mr-4 hover:underline md:mr-6 ">
              Layanan Kami
            </a>
          </li>
          <li>
            <Link href="/blog" class="mr-4 hover:underline md:mr-6">
              Blog
            </Link>
          </li>
          <li>
            <Link href="/jurnal_kami" class="mr-4 hover:underline md:mr-6">
              Jurnal Kami
            </Link>
          </li>
          <li>
            <a href="/#kontak" class="mr-4 hover:underline md:mr-6">
              Kontak
            </a>
          </li>
        </ul>
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023{" "}
          <a href="#" class="hover:underline">
            Transpublika Publisher
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
